import React from 'react';

import SEO from '../components/seo';

const IndexPage = () => (
  <>
    <SEO
      title="Strona główna"
      description="Professional Piercers Poland - Stowarzyszenie zrzeszające profesjonalnych polskich piercerów."
    />
  </>
);

export default IndexPage;
